<template>
  <section v-if="!this.$store.state.page.isMobile" id="contents_wrap">
    <h1 style="font-size: 40pt; text-align: center; margin-top: 35px; font-weight:bold; color:white;">
      VIKBET 레벨별 혜택 안내
    </h1>
    <h1 style="font-size: 10pt; text-align: center; margin-top: 10px">
      소중한 VIKBET회원님들을 위한 레벨별 혜택, 참고하시어 이용하시는데 불편함이
      없으시길 바랍니다.
    </h1>
    <div style="align:center;">
      <div style="width: 80%; margin: 0 auto;">
        <div style="margin-top: 50px">
          <span style="font-size: 16pt;">레벨별 보너스 혜택</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: %)</span
          >
        </div>
        <div style="min-height: 350px">
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th style="width: 30%">보너스 혜택</th>
                  <th style="width: 14%">1레벨</th>
                  <th style="width: 14%">2레벨</th>
                  <th style="width: 14%">3레벨</th>
                  <th style="width: 14%">4레벨</th>
                  <th style="width: 14%">5레벨</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>첫충전 보너스</td>
                  <td>5</td>
                  <td>5</td>
                  <td>6</td>
                  <td>6</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>매충전 보너스</td>
                  <td>5</td>
                  <td>5</td>
                  <td>5</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>스포츠 1폴더 낙첨</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 2폴더 낙첨</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 3폴더 낙첨</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>스포츠 4폴더 낙첨</td>
                  <td>1.5</td>
                  <td>1.5</td>
                  <td>1.5</td>
                  <td>1.5</td>
                  <td>1.5</td>
                </tr>
                <tr>
                  <td>스포츠 5폴더 이상 낙첨</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>인플레이/라이브 낙첨</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 1폴더 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>스포츠 2폴더 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>스포츠 3폴더 롤링</td>
                  <td>0.3</td>
                  <td>0.35</td>
                  <td>0.4</td>
                  <td>0.45</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 4폴더 롤링</td>
                  <td>0.35</td>
                  <td>0.4</td>
                  <td>0.45</td>
                  <td>0.5</td>
                  <td>0.55</td>
                </tr>
                <tr>
                  <td>스포츠 5폴더 이상 롤링</td>
                  <td>0.4</td>
                  <td>0.45</td>
                  <td>0.5</td>
                  <td>0.55</td>
                  <td>0.6</td>
                </tr>
                <tr>
                  <td>인플레이/라이브 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>카지노/로투스/미니게임 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>스포츠 1폴더 추천인 낙첨금</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 2폴더 추천인 낙첨금</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 3폴더 추천인 낙첨금</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>스포츠 4폴더 추천인 낙첨금</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>스포츠 5폴더 이상 추천인 낙첨금</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>추천인 낙첨금 인플레이/라이브</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="margin-top: 50px;">
          <span style="font-size: 16pt">레벨별 베팅금액</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: 만원)</span
          >
        </div>
        <div style="min-height: 350px">
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th colspan="2" rowspan="2" style="width: 15%">회원레벨</th>
                  <th colspan="2" style="width: 14%">한국형</th>
                  <th colspan="2" style="width: 14%">스페셜</th>
                  <th colspan="2" style="width: 14%">유럽형</th>
                  <th colspan="2" style="width: 14%">라이브</th>
                  <th colspan="2" style="width: 14%">인플레이</th>
                </tr>
                <tr>
                  <th>최대베팅금액</th>
                  <th>상한가</th>
                  <th>최대베팅금액</th>
                  <th>상한가</th>
                  <th>최대베팅금액</th>
                  <th>상한가</th>
                  <th>최대베팅금액</th>
                  <th>상한가</th>
                  <th>최대베팅금액</th>
                  <th>상한가</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2" >1레벨</th>
                  <td>300</td>
                  <td>1,000</td>
                  <td>100</td>
                  <td>300</td>
                  <td>300</td>
                  <td>1,000</td>
                  <td>100</td>
                  <td>300</td>
                  <td rowspan="7">리그별베팅한도</td>
                  <td rowspan="7">450</td>
                </tr>
                <tr>
                  <th colspan="2" >2레벨</th>
                  <td>300</td>
                  <td>1,500</td>
                  <td>100</td>
                  <td>300</td>
                  <td>300</td>
                  <td>1,500</td>
                  <td>100</td>
                  <td>300</td>
                </tr>
                <tr>
                  <th colspan="2" >3레벨</th>
                  <td>500</td>
                  <td>2,000</td>
                  <td>100</td>
                  <td>300</td>
                  <td>500</td>
                  <td>2,000</td>
                  <td>100</td>
                  <td>300</td>
                </tr>
                <tr>
                  <th rowspan="2">4레벨</th>
                  <th>다폴더</th>
                  <td rowspan="2">500</td>
                  <td rowspan="2">2,500</td>
                  <td>150</td>
                  <td>450</td>
                  <td rowspan="2">500</td>
                  <td rowspan="2">2,500</td>
                  <td rowspan="2">100</td>
                  <td rowspan="2">300</td>
                </tr>
                <tr>
                  <th>단폴더</th>
                  <td>100</td>
                  <td>300</td>
                </tr>
                <tr>
                  <th rowspan="2">5레벨</th>
                  <th>다폴더</th>
                  <td rowspan="2">1,000</td>
                  <td rowspan="2">3,000</td>
                  <td>150</td>
                  <td>450</td>
                  <td rowspan="2">1,000</td>
                  <td rowspan="2">3,000</td>
                  <td rowspan="2">150</td>
                  <td rowspan="2">450</td>
                </tr>
                <tr>
                  <th>단폴더</th>
                  <td>100</td>
                  <td>300</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="margin-top: 50px">
          <span style="font-size: 16pt">레벨별 토큰, 미니게임 베팅금액</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: 천원)</span
          >
        </div>
        <div style="min-height: 350px">
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th rowspan="2" style="width: 12%">회원레벨</th>
                  <th colspan="2" style="width: 12%">토큰 룰렛</th>
                  <th colspan="2" style="width: 12%">하이로우</th>
                  <th colspan="2" style="width: 12%">토큰 바카라 1번</th>
                  <th colspan="2" style="width: 12%">토큰 바카라 2-5번</th>
                  <th colspan="2" style="width: 12%">토큰 바카라 6번</th>
                  <th colspan="2" style="width: 12%">로투스</th>
                  <th colspan="2" style="width: 12%">동행 파워볼</th>
                </tr>
                <tr>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1레벨</th>
                  <td rowspan="5">1</td>
                  <td rowspan="5">1,000</td>
                  <td rowspan="5">1</td>
                  <td rowspan="5">1,000</td>
                  <td rowspan="5">1</td>
                  <td rowspan="5">100</td>
                  <td rowspan="5">10</td>
                  <td rowspan="5">1,000</td>
                  <td rowspan="5">20</td>
                  <td rowspan="5">2,000</td>
                  <td>10</td>
                  <td>500</td>
                  <td>10</td>
                  <td>2,000</td>
                </tr>
                <tr>
                  <th>2레벨</th>
                  <td>10</td>
                  <td>500</td>
                  <td>10</td>
                  <td>2,000</td>
                </tr>
                <tr>
                  <th>3레벨</th>
                  <td>10</td>
                  <td>1,000</td>
                  <td>10</td>
                  <td>2,000</td>
                </tr>
                <tr>
                  <th>4레벨</th>
                  <td>10</td>
                  <td>1,000</td>
                  <td>10</td>
                  <td>2,000</td>
                </tr>
                <tr>
                  <th>5레벨</th>
                  <td>10</td>
                  <td>1,000</td>
                  <td>10</td>
                  <td>2,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <span style="float: right; margin-right: 15px"
              >상기 내용은 업데이트 반영 시점에 따라 실제 적용 수치와 일치하지 않을
              수 있으며, 정확한 실시간 정보는 문의를 통해 확인 가능합니다.</span
            >
    </div>

    <!-- <div class="contents">
      <img
        style="display: block; margin-left: auto; margin-right: auto"
        src="https://s3.ap-northeast-1.amazonaws.com/static-sdsoft/487f7b22f68312d2c1bbc93b1aea445b-%C3%AB%C2%A0%C2%88%C3%AB%C2%A0%C2%88%C3%AB%C2%B3%C2%84%20%C3%AD%C2%98%C2%9C%C3%AD%C2%83%C2%9D%C3%AC%C2%95%C2%88%C3%AB%C2%82%C2%B4%202022-12-31.png"
      />
    </div> -->
  </section>
  <div v-else>
    <h1 style="font-size: 20pt; text-align: center; margin-top: 35px; font-weight:bold; color:white;">
      VIKBET 레벨별 혜택 안내
    </h1>
    <h1 style="font-size: 7pt; text-align: center; margin-top: 10px">
      소중한 VIKBET회원님들을 위한 레벨별 혜택,<br>참고하시어 이용하시는데 불편함이
      없으시길 바랍니다.
    </h1>
    <div style="margin-top: 30px">
          <span style="font-size: 14pt;">레벨별 보너스 혜택</span
          ><span style="float: right; font-size: 8pt; margin-top: 10px"
            >(단위: %)</span
          >
    </div>
    <div style="min-height: 350px">
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row" height=5>
                  <th style="width: 45%;" rowspan="2">보너스 혜택</th>
                  <th style="width: 55%" colspan="5">레벨</th>
                </tr>
                <tr>
                  <th style="width: 11%">1</th>
                  <th style="width: 11%">2</th>
                  <th style="width: 11%">3</th>
                  <th style="width: 11%">4</th>
                  <th style="width: 11%">5</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>첫충전 보너스</td>
                  <td>5</td>
                  <td>5</td>
                  <td>6</td>
                  <td>6</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>매충전 보너스</td>
                  <td>5</td>
                  <td>5</td>
                  <td>5</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>스포츠 1폴더 낙첨</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 2폴더 낙첨</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 3폴더 낙첨</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>스포츠 4폴더 낙첨</td>
                  <td>1.5</td>
                  <td>1.5</td>
                  <td>1.5</td>
                  <td>1.5</td>
                  <td>1.5</td>
                </tr>
                <tr>
                  <td>스포츠 5폴더 이상 낙첨</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>인플레이/라이브 낙첨</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 1폴더 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>스포츠 2폴더 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>스포츠 3폴더 롤링</td>
                  <td>0.3</td>
                  <td>0.35</td>
                  <td>0.4</td>
                  <td>0.45</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 4폴더 롤링</td>
                  <td>0.35</td>
                  <td>0.4</td>
                  <td>0.45</td>
                  <td>0.5</td>
                  <td>0.55</td>
                </tr>
                <tr>
                  <td>스포츠 5폴더 이상 롤링</td>
                  <td>0.4</td>
                  <td>0.45</td>
                  <td>0.5</td>
                  <td>0.55</td>
                  <td>0.6</td>
                </tr>
                <tr>
                  <td>인플레이/라이브 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>카지노/로투스/미니게임 롤링</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>스포츠 1폴더 추천인 낙첨금</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 2폴더 추천인 낙첨금</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>스포츠 3폴더 추천인 낙첨금</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>스포츠 4폴더 추천인 낙첨금</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>스포츠 5폴더 이상 추천인 낙첨금</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>추천인 낙첨금 인플레이/라이브</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                  <td>0.5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="margin-top: 50px;">
          <span style="font-size: 16pt">레벨별 베팅금액(프리매치)</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: 만원)</span
          >
        </div>
        <div style="min-height: 350px">
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th colspan="2" rowspan="2" style="width: 20%">회원레벨</th>
                  <th colspan="2" style="width: 20%">한국형</th>
                  <th colspan="2" style="width: 20%">스페셜</th>
                  <th colspan="2" style="width: 20%">유럽형</th>
                </tr>
                <tr>
                  <th style="width: 10%">최대<br>베팅</th>
                  <th style="width: 10%">상한가</th>
                  <th style="width: 10%">최대<br>베팅</th>
                  <th style="width: 10%">상한가</th>
                  <th style="width: 10%">최대<br>베팅</th>
                  <th style="width: 10%">상한가</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2" style="width: 7%">1</th>
                  <td>300</td>
                  <td>1,000</td>
                  <td>100</td>
                  <td>300</td>
                  <td>300</td>
                  <td>1,000</td>
                </tr>
                <tr>
                  <th colspan="2">2</th>
                  <td>300</td>
                  <td>1,500</td>
                  <td>100</td>
                  <td>300</td>
                  <td>300</td>
                  <td>1,500</td>
                </tr>
                <tr>
                  <th colspan="2">3</th>
                  <td>500</td>
                  <td>2,000</td>
                  <td>100</td>
                  <td>300</td>
                  <td>500</td>
                  <td>2,000</td>
                </tr>
                <tr>
                  <th rowspan="2">4</th>
                  <th>다폴더</th>
                  <td rowspan="2">500</td>
                  <td rowspan="2">2,500</td>
                  <td>150</td>
                  <td>450</td>
                  <td rowspan="2">500</td>
                  <td rowspan="2">2,500</td>
                </tr>
                <tr>
                  <th>단폴더</th>
                  <td>100</td>
                  <td>300</td>
                </tr>
                <tr>
                  <th rowspan="2">5</th>
                  <th>다폴더</th>
                  <td rowspan="2">1,000</td>
                  <td rowspan="2">3,000</td>
                  <td>150</td>
                  <td>450</td>
                  <td rowspan="2">1,000</td>
                  <td rowspan="2">3,000</td>
                </tr>
                <tr>
                  <th>단폴더</th>
                  <td>100</td>
                  <td>300</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="margin-top: 50px;">
          <span style="font-size: 16pt">레벨별 베팅금액(라이브/인플레이)</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: 만원)</span
          >
        </div>
        <div style="min-height: 200px">
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th rowspan="2" style="width: 20%">회원레벨</th>
                  <th colspan="2" style="width: 20%">라이브</th>
                  <th colspan="2" style="width: 20%">인플레이</th>
                </tr>
                <tr>
                  <th style="width: 10%">최대<br>베팅</th>
                  <th style="width: 10%">상한가</th>
                  <th style="width: 10%">최대<br>베팅</th>
                  <th style="width: 10%">상한가</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="width: 7%">1레벨</th>
                  <td>100</td>
                  <td>300</td>
                  <td>리그별베팅한도</td>
                  <td>450</td>
                </tr>
                <tr>
                  <th>2레벨</th>
                  <td>100</td>
                  <td>300</td>
                  <td>리그별베팅한도</td>
                  <td>450</td>
                </tr>
                <tr>
                  <th>3레벨</th>
                  <td>100</td>
                  <td>300</td>
                  <td>리그별베팅한도</td>
                  <td>450</td>
                </tr>
                <tr>
                  <th>4레벨</th>
                  <td>100</td>
                  <td>300</td>
                  <td>리그별베팅한도</td>
                  <td>450</td>
                </tr>
                <tr>
                  <th>5레벨</th>
                  <td>150</td>
                  <td>450</td>
                  <td>리그별베팅한도</td>
                  <td>450</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="margin-top: 50px">
          <span style="font-size: 16pt">토큰게임 베팅금액(레벨무관)</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: 천원)</span
          >
        </div>
        <div>
          <div>
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th style="width: 17%">구분</th>
                  <th style="width: 11%">룰렛</th>
                  <th style="width: 11%">하이로우</th>
                  <th style="width: 11%">바카라<br>1번</th>
                  <th style="width: 11%">바카라<br>2-5번</th>
                  <th style="width: 11%">바카라<br>6번</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>최소베팅금액</th>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>10</td>
                  <td>20</td>
                </tr>
                <tr>
                  <th>최대베팅금액</th>
                  <td>1,000</td>
                  <td>1,000</td>
                  <td>100</td>
                  <td>1,000</td>
                  <td>2,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="margin-top: 50px">
          <span style="font-size: 16pt">레벨별 미니게임 베팅금액</span
          ><span style="float: right; font-size: 10pt; margin-top: 10px"
            >(단위: 만원)</span
          >
        </div>
        <div style="margin-bottom:60px;">
          <div style="margin-bottom:15px;">
            <table
              id="tableAll1"
              cellspacing="0"
              cellpadding="0"
              width="100%"
              class="dataTable no-footer"
              role="grid"
              style="width: 100%"
            >
              <thead>
                <tr role="row">
                  <th rowspan="2" style="width: 20%">회원레벨</th>
                  <th colspan="2" style="width: 40%">로투스</th>
                  <th colspan="2" style="width: 40%">동행 파워볼</th>
                </tr>
                <tr>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                  <th>최소<br>베팅금액</th>
                  <th>최대<br>베팅금액</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1레벨</th>
                  <td>1</td>
                  <td>50</td>
                  <td>1</td>
                  <td>200</td>
                </tr>
                <tr>
                  <th>2레벨</th>
                  <td>1</td>
                  <td>50</td>
                  <td>1</td>
                  <td>200</td>
                </tr>
                <tr>
                  <th>3레벨</th>
                  <td>1</td>
                  <td>100</td>
                  <td>1</td>
                  <td>200</td>
                </tr>
                <tr>
                  <th>4레벨</th>
                  <td>1</td>
                  <td>100</td>
                  <td>1</td>
                  <td>200</td>
                </tr>
                <tr>
                  <th>5레벨</th>
                  <td>1</td>
                  <td>100</td>
                  <td>1</td>
                  <td>200</td>
                </tr>
              </tbody>
            </table>
          </div>
          <span style="float: right; margin-right: 15px; text-align:right;"
              >상기 내용은 업데이트 반영 시점에 따라 실제 적용 수치와 일치하지 않을
              수 있으며, 정확한 실시간 정보는 문의를 통해 확인 가능합니다.</span
            >
        </div>
        
  </div>
</template>

<script>
export default {
  name: "Level",
};
</script>

<style>
.contents {
  margin-top: 20px;
}
</style>
