<template>
  <transition name="fade">
    <div class="popup_blank" v-if="popup_bg">
      <!--메세지 팝업-->
      <div class="new_message_popup" v-if="notes">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('notes')">
          <img src="@/assets/img/ico/popup_close.png">
        </span>
        </div>
        <div class="popup_content">
          <img src="@/assets/img/ico/question_icon.png">
          <div class="pop_text">
            쪽지가 도착했습니다.
          </div>
          <div class="btnBox02">
            <a @click="closePopup('notes')" class="btn05">확인</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessEsportsMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessEsportsMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processEsportsMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessEsportsMulti')">아니오</a>
          </div>
        </div>
      </div>
      <!--당첨 팝업	-->
      <div class="new_message_popup" v-if="roulette">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn"  @click="closePopup('roulette')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <img src="@/assets/img/main/menu_big_00.png">
          <div class="pop_text">
            축하합니다!<br>
            행운의 1000 포인트가 당첨 되었어요<br>
          </div>
          <div class="btnBox02">
            <a @click="closePopup('roulette')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--베팅 팝업-->
      <!-- 벳컨-->
      <div class="new_message_popup" v-if="bettingProcess">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcess')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="process" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcess')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessSingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessSingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processSingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessSingle')">아니오</a>
          </div>
        </div>
      </div>

      <div class="new_message_popup" v-if="bettingProcessSpecialMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessSpecialMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processSpecialMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessSpecialMulti')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessSpecialSingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessSpecialSingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processSpecialSingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessSpecialSingle')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessEuropeanMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessEuropeanMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processEuropeanMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessEuropeanMulti')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessEuropeanSingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessEuropeanSingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processEuropeanSingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessEuropeanSingle')">아니오</a>
          </div>
        </div>
      </div>
      <!-- 엘스포츠 -->
      <div class="new_message_popup" v-if="bettingProcessLsportInplayMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessLsportInplayMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLsportInplayMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessLsportInplayMulti')">아니오</a>
          </div>
        </div>
      </div>

      <div class="new_message_popup" v-if="bettingProcessKoreanMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessKoreanMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processKoreanMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessKoreanMulti')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessKoreanSingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessKoreanSingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processKoreanSingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessKoreanSingle')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessLsportEuropeanMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessLsportEuropeanMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLsportEuropeanMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessLsportEuropeanMulti')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessLsportEuropeanSingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessLsportEuropeanSingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLsportEuropeanSingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessLsportEuropeanSingle')">아니오</a>
          </div>
        </div>
      </div>


      <div class="new_message_popup" v-if="bettingProcessInplaySingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessInplaySingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processInplaySingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessInplaySingle')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessInplayMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessInplayMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processInplayMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessInplayMulti')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessLiveSingle">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessLiveSingle')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLiveSingle" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessLiveSingle')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessLiveMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessLiveMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLiveMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessLiveMulti')">아니오</a>
          </div>
        </div>
      </div>
      <div class="new_message_popup" v-if="bettingProcessLsportSpecialMulti">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingProcessLsportSpecialMulti')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLsportSpecialMulti" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingProcessLsportSpecialMulti')">아니오</a>
          </div>
        </div>
      </div>
      <!--베팅 팝업-->
      <div class="new_message_popup" v-if="bettingLotusProcess">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingLotusProcess')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processLotus" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingLotusProcess')">아니오</a>
          </div>
        </div>
      </div>
      <!--베팅 팝업-->
      <div class="new_message_popup" v-if="bettingMiniProcess">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingMiniProcess')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅을 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="processMini" style="margin-right: 5px" class="btn05">예</a>
            <a class="btn04" @click="closePopup('bettingMiniProcess')">아니오</a>
          </div>
        </div>
      </div>
      <!--베팅 진행중-->
      <div class="new_message_popup" v-if="bettingProcessing">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
        </div>
        <div class="popup_content" style="text-align: center">
          <Loading></Loading>
        </div>
      </div>

      <!--에러 메시지-->
      <div class="new_message_popup" v-if="error" >
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('error')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            {{ errorMsg }}
          </div>
          <div class="btnBox02">
            <a @click="closePopup('error')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--에러 메시지-->
      <div class="new_message_popup" v-if="error2">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('error2')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            {{ errorMsg }}
          </div>
          <div class="btnBox02">
            <a @click="closePopup('error2')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--에러 메시지-->
      <div class="new_message_popup" v-if="error3">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closeSignupPopup('error2')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            {{ errorMsg }}
          </div>
          <div class="btnBox02">
            <a @click="closeSignupPopup('error2')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--에러 메시지-->
      <div class="new_message_popup" v-if="deduction">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('deduction')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            {{ errorMsg }}
          </div>
          <div class="btnBox02">
            <a @click="closePopup('deduction')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--베팅 상태 변경 메시지-->
      <div class="new_message_popup" v-if="bettingStatus">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingStatus')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            {{ errorMsg }}
          </div>
          <div class="btnBox02">
            <a @click="closePopup('bettingStatus')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--베팅 배당 변경X 팝업-->
      <div class="new_message_popup" v-if="bettingUpdate">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingUpdate')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            배당이 변경되었습니다. 재베팅하시기 바랍니다.
          </div>
          <div class="btnBox02">
            <a @click="closePopup('bettingUpdate')" class="btn05">확인</a>
          </div>
        </div>
      </div>

      <!--베팅 배당 변경O 팝업-->
      <div class="new_message_popup" v-if="bettingUpdateProcess">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingUpdateProcess')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            배당이 변경 되었습니다. 변경된 배당으로 베팅하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="process" style="margin-right: 5px" class="btn05">예</a>
            <a @click="closePopup('bettingUpdateProcess')" class="btn04">아니오</a>
          </div>
        </div>
      </div>

      <!--베팅 배당 취소 팝업-->
      <div class="new_message_popup" v-if="bettingCancelProcess">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingUpdateProcess')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content">
          <div class="pop_text">
            베팅취소 하시겠습니까?
          </div>
          <div class="btnBox02">
            <a @click="process" style="margin-right: 5px" class="btn05">예</a>
            <a @click="closePopup('bettingCancel')" class="btn04">아니오</a>
          </div>
        </div>
      </div>

      <!--베팅 성공팝업-->
      <div class="new_message_popup" v-if="bettingSuccess">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('bettingSuccess')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content" style="padding: 20px">
          <div class="pop_text">
            베팅에 성공하셨습니다.
          </div>
        </div>
      </div>
      <!--점검중-->
      <div class="new_message_popup" v-if="fixing">
        <div class="bg_mask_pop_title">
          <span class="popup_logo"><img src="@/assets/img/main/vikbet_logo_main.png" style="width: 100px;"></span>
          <span class="popup_close closeLogoutBtn" @click="closePopup('fixing')"><img src="@/assets/img/main/popup_close_one.png"></span>
        </div>
        <div class="popup_content" style="padding: 20px">
          <div class="pop_text">
            점검중 입니다.
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Loading from '@/components/Loading'
import MSG from '../contants/msg'
import { mapState } from "vuex";

export default {
  name: "Popup",
  components: {
    Loading
  },
  computed: {
    popup_bg: function(){
      return this.$store.getters['BACKGROUND']
    },
    ...mapState({
      notes: state => state.modals.notes,
      cart: state => state.modals.cart,
      signup: state => state.modals.signup,
      bettingSuccess: state => state.modals.bettingSuccess,
      bettingStatus: state => state.modals.bettingStatus,
      bettingUpdate: state => state.modals.bettingUpdate,
      bettingUpdateProcess: state => state.modals.bettingUpdateProcess,
      bettingProcessKoreanMulti: state => state.modals.bettingProcessKoreanMulti,
      bettingProcessKoreanSingle: state => state.modals.bettingProcessKoreanSingle,
      bettingProcessLsportEuropeanMulti: state => state.modals.bettingProcessLsportEuropeanMulti,
      bettingProcessLsportEuropeanSingle: state => state.modals.bettingProcessLsportEuropeanSingle,
      bettingProcessLsportSpecialMulti: state => state.modals.bettingProcessLsportSpecialMulti,
      bettingProcessLsportInplayMulti: state => state.modals.bettingProcessLsportInplayMulti,
      bettingProcessSpecialMulti: state => state.modals.bettingProcessSpecialMulti,
      bettingProcessSpecialSingle: state => state.modals.bettingProcessSpecialSingle,
      bettingProcessEuropeanMulti: state => state.modals.bettingProcessEuropeanMulti,
      bettingProcessEuropeanSingle: state => state.modals.bettingProcessEuropeanSingle,
      bettingProcess: state => state.modals.bettingProcess,
      bettingProcessSingle: state => state.modals.bettingProcessSingle,
      bettingProcessInplayMulti: state => state.modals.bettingProcessInplayMulti,
      bettingProcessInplaySingle: state => state.modals.bettingProcessInplaySingle,
      bettingProcessLiveMulti: state => state.modals.bettingProcessLiveMulti,
      bettingProcessLiveSingle: state => state.modals.bettingProcessLiveSingle,
      bettingProcessEsportsMulti: state => state.modals.bettingProcessEsportsMulti,
      bettingProcessing: state => state.modals.bettingProcessing,
      bettingMiniProcess: state => state.modals.bettingMiniProcess,
      bettingCancelProcess: state => state.modals.bettingCancelProcess,
      bettingCancelSuccess: state => state.modals.bettingCancelSuccess,
      bettingLotusProcess: state => state.modals.bettingLotusProcess,
      error: state => state.modals.error,
      error2: state => state.modals.error2,
      error3: state => state.modals.error3,
      roulette: state => state.modals.roulette,
      fixing: state => state.modals.fixing,
      errorMsg: state => state.modals.errorMsg,
      deduction: state => state.modals.deduction,
      user: state => state.user.user,
      inpCartItem: state => state.user.process,
}),
  },
  methods: {
    closePopup: function(data){
      if (data === 'notes') {
        if (this.$route.path !== '/bbs/msg/l') {
          this.$router.push('/bbs/msg/l')
        }
        this.$store.dispatch('INVISIBLE_ALL_MODALS');
      }
      if (data === 'bettingSuccess') {
        // if (
        //     this.$route.path.indexOf('inplay') > 0 ||
        //     this.$route.path.indexOf('korean') > 0 ||
        //     this.$route.path.indexOf('detail') > 0 ||
        //     this.$route.path.indexOf('live') > 0 ||
        //     this.$route.path.indexOf('korean') > 0 ||
        //     this.$route.path.indexOf('european') > 0
        // ){
        //   this.$store.dispatch('modals/closeAllPopup', 'modals/closeAllPopup');
        //   this.$router.push('/betResult')
        // }
        this.$store.dispatch('INVISIBLE_ALL_MODALS');
      }
      if (data === 'bettingUpdate') {
        this.clearCart('sports')
      }
      if (data === 'error') {
        if (this.errorMsg === MSG.isNotLogin && this.$isMobile()) {
          this.$router.push({path: '/login'})
        }
        this.$store.dispatch('INVISIBLE_ALL_MODALS');
      }
      if (data === 'deduction') {
        this.$store.dispatch('INVISIBLE_ALL_MODALS');
      }
      if (data === 'error2') {
        this.$router.push('/main');
        this.$store.dispatch('INVISIBLE_ALL_MODALS');
      }
      if (data === 'bettingProcess'){
        this.$store.dispatch('INVISIBLE_ALL_MODALS');
      }
      this.$store.dispatch('INVISIBLE_MODAL', data)
    },
    process: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcess');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_KOREAN_BETCON_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });

    },
    processSingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_KOREAN_BETCON_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });

    },

    processKoreanMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessKoreanMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_KOREAN_LSPORT_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });

    },
    processKoreanSingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessKoreanSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_KOREAN_LSPORT_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });
    },
    processLsportEuropeanMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessLsportEuropeanMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_EUROPEAN_LSPORT_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });

    },
    processLsportEuropeanSingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessLsportEuropeanSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_EUROPEAN_LSPORT_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });
    },
    processSpecialMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessSpecialMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_KOREAN_BETCON_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });

    },
    processSpecialSingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessSpecialSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_KOREAN_BETCON_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });
    },
    processEuropeanMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessEuropeanMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_EUROPEAN_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });

    },
    processEuropeanSingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessEuropeanSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_EUROPEAN_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });
    },
    processInplayMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessInplayMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      // console.log(this.user.selfCert, '인플레이 멀티', this.inpCartItem.bettings);
      if (this.user.selfCert === 'y') {
        setTimeout(() => {
          this.$store.dispatch('PROCESS_INPLAY_DELAY_BETTING').then(e => {
            const result = e.data;
            console.log(result)
            if (result.success){
              this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
              this.$store.dispatch('ME')
              this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
            } else {
              this.$store.dispatch('SHOW_ERR_MSG', result.msg);
            }
          })
          .catch(err => {
            this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });
        }, 15000)
      } else {
        this.$store.dispatch('PROCESS_INPLAY_BETTING').then(e => {
          const result = e.data;
          if (result.success){
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('ME')
            this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
          } else {
            this.$store.dispatch('SHOW_ERR_MSG', result.msg);
          }
        })
        .catch(err => {
          this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
        });
      }
    },
    processInplaySingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessInplaySingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      // console.log(this.user.selfCert, '인플레이 싱글', this.inpCartItem.bettings);
      if (this.user.selfCert === 'y') {
        setTimeout(() => {
          this.$store.dispatch('PROCESS_INPLAY_DELAY_BETTING_SINGLE').then(e => {
            const result = e.data
            console.log(result);
            if (result.success){
              this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
              this.$store.dispatch('ME')
              this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
            } else {
              this.$store.dispatch('SHOW_ERR_MSG', result.msg);
            }
          })
          .catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });
        }, 15000);
      } else {
        this.$store.dispatch('PROCESS_INPLAY_BETTING_SINGLE').then(e => {
          const result = e.data;
          console.log(result);
          if (result.success){
            this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
            this.$store.dispatch('ME')
            this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
          } else {
            this.$store.dispatch('SHOW_ERR_MSG', result.msg);
          }
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
        });
      }

    },
    processLsportInplayMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessLsportInplayMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_INPLAY_LSPORT_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          });

    },


    processLiveMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessLiveMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_LIVE_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
          .catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);

          });

    },
    processLiveSingle: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessLiveSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_LIVE_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });
    },
    processLsportSpecialMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessLsportSpecialMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_SPECIAL_LSPORT_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });

    },
    processLotus: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingLotusProcess');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_LOTUS_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });
    },
    processMini: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingMiniProcess');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_MINI_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        console.log(err)
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });
    },
    processInplay: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessEuropeanSingle');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_EUROPEAN_BETTING_SINGLE').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SET_ERR_MSG', result.msg);
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });

    },
    cancelProcess: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingCancelProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');

      this.$store.dispatch('cart/_confirm').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('auth/setUser', result.payload.member)
          this.$store.dispatch('SHOW_MODAL', 'bettingCancelSuccess');
        }
      })
      .catch(err => {
        this.$store.dispatch('SET_ERR_MSG', err.response.data.error);
        this.$store.dispatch('INVISIBLE_MODAL', 'bettingCancelProcessing');
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });

    },

    clearCart: function(type){
      this.$store.dispatch('cart/clearCart', type)
    },
    closeSignupPopup: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'error3');
      this.$store.dispatch('SHOW_MODAL', 'signup');
    },
    processEsportsMulti: function(){
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessEsportsMulti');
      this.$store.dispatch('INVISIBLE_MODAL', 'bettingUpdateProcess');
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessing');
      this.$store.dispatch('PROCESS_ESPORTS_BETTING').then(e => {
        const result = e.data;
        if (result.success){
          this.$store.dispatch('INVISIBLE_MODAL', 'bettingProcessing');
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_MODAL', 'bettingSuccess');
        } else {
          this.$store.dispatch('SHOW_ERR_MSG', result.msg);
        }
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
