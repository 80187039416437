<template>
  <section v-if="!this.$store.state.page.isMobile">
    <!--    <h2 class="subTitle">스포츠</h2>-->

    <div class="inner" style="margin-top: 20px; padding: 0; width: 1261px;">
      <div class="cate2">
        <div class="box">
          <a class="all" @click="changeCategory(null)">ALL</a>
          <a class="c2" @click="changeCategory('축구')">FOOTBALL</a>
          <a class="c1" @click="changeCategory('야구')">BASEBALL</a>
          <a class="c3" @click="changeCategory('농구')">BASKETBALL</a>
          <a class="c4" @click="changeCategory('배구')">VOLLEYBALL</a>
          <a class="c5" @click="changeCategory('아이스하키')">HOCKEY</a>
          <a class="c6" @click="changeCategory('이스포츠')">E-SPORTS</a>
          <a class="c7" @click="changeCategory('미식축구')">AMERICAN FOOTBALL</a>
        </div>
      </div>
    </div>

    <div class="nbox" style="width: 1261px;">
      <div class="contLeft" style="min-height: 1000px; width: 950px;">
        <div class="warning_live" style="background-color: #303435; color: white; padding-left:7px; padding-top:7px;">
          <p>※ 실시간 베팅 주의사항</p> <br>

          <p>베팅 전 필히 팀명 확인후 베팅해주세요</p> <br>

          <p>한국형/유럽형/인플레이 팀명 표기 순서가 상이할수 있습니다.</p> <br>

          <p>회원님의 불찰로 인한 베팅의 경우 운영진은 책임지지 않습니다.</p> <br>
        </div>
        <KoreanFolders v-if="isLoading" :lists="lists" :add-cart="addCart" :check-cart="checkCart"></KoreanFolders>
        <Loading style="text-align: center; margin-top: 20%;" v-else></Loading>
      </div>

      <!--      카트      -->
      <!-- <div width="260" class="contRight" :class="{'fixedCart': isScrollDown}" valign="top" style="left: calc(calc(1190px * 0.77) + calc((100% - 1190px) / 2) + 10px); position: fixed;" v-if="!sticky">
        <NewCart :toggle-stick="toggleStick"></NewCart>
      </div>
      <div width="260" class="contRight" valign="top" style="left: calc(calc(1190px * 0.77) + calc((100% - 1190px) / 2) + 10px); position: absolute;" v-else>
        <NewCart :toggle-stick="toggleStick"></NewCart>
      </div> -->
      <NewCart v-if="user" :toggle-auto-updating="toggleAutoUpdating" :auto-updating="autoUpdating"></NewCart>
      <NewCartNotLogin v-else :toggle-auto-updating="toggleAutoUpdating" :auto-updating="autoUpdating"></NewCartNotLogin>
    </div>
  </section>
  <div v-else>
    <div class="categorys" style="margin-top: 3px; padding: 0;">
      <button @click="changeCategory(null)" :class="{'active': this.category === null}">전체</button>
      <button @click="changeCategory('축구')" :class="{'active': this.category === '축구'}"><img src="@/assets/img/ico/1.png"></button>
      <button @click="changeCategory('야구')" :class="{'active': this.category === '야구'}"><img style="height: 23px; width: 23px;" src="@/assets/img/ico/1111.png"></button>
      <button @click="changeCategory('농구')" :class="{'active': this.category === '농구'}"><img src="@/assets/img/ico/3.png"></button>
      <button @click="changeCategory('배구')" :class="{'active': this.category === '배구'}"><img src="@/assets/img/ico/5.png"></button>
      <button @click="changeCategory('아이스하키')" :class="{'active': this.category === '아이스하키'}"><img src="@/assets/img/ico/2.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/4.png"></button>-->
      <button @click="changeCategory('이스포츠')" :class="{'active': this.category === '이스포츠'}"><img src="@/assets/img/ico/77.png"></button>
      <button @click="changeCategory('미식축구')" :class="{'active': this.category === '미식축구'}"><img src="@/assets/img/ico/6.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/44.png"></button>-->
    </div>

    <template v-if="isLoading">
      <!-- 게임 데이터 -->
      <div class="m-game3" v-if="originList && originList.length === 0" style="min-height: 100px; text-align: center;">
        <div class="day" style="margin-top: 40px;">
          <span class="t2" style="text-align: center;">진행중인 경기가 없습니다.</span>
        </div>
      </div>
      <!-- 경기목록   -->
      <MWrapperFolder
          v-else-if="lists.length > 0"
          :check-cart="checkCart"
          :add-cart="addCart"
          :lists="lists"
          :bonus="bonus"
      ></MWrapperFolder>
      <transition name="fade">
        <MCart v-if="user && this.cartItem.length > 0" :toggle-auto-updating="toggleAutoUpdating" :auto-updating="autoUpdating"></MCart>
      </transition>
    </template>
    <template v-else>
      <!-- 인디케이터 -->
      <div class="m-game3" style="min-height: 100px; text-align: center;">
        <Loading></Loading>
      </div>
    </template>
  </div>
</template>

<script>
import MCart from '@/components/Betting/MKoreanLiveCartNew'
import NewCart from '@/components/Betting/KoreanLiveCartNew'
import NewCartNotLogin from '@/components/Betting/LsportKoreanCartNewNotLogin'
import Loading from '@/components/Loading'
import KoreanFolders from '@/components/Game/Sports/Korean/Folders3'
import MWrapperFolder from '@/components/Game/Sports/Korean/MWrapperFolder2'

import moment from "moment";
import {mapState} from "vuex";
import MSG from "@/contants/msg";
export default {
  name: "Live",
  components: {
    MCart,
    NewCart,
    KoreanFolders,
    MWrapperFolder,
    Loading,
    NewCartNotLogin
  },
  data: function(){
    return {
      category: '',
      isLoading: false,
      search: false,
      keyword: null,
      autoUpdating: false,
      lists : null,
      sticky: false,
      payload: {},
      isScrollDown: false,
    }
  },
  computed: {
    //보너스
    bonus: function(){
      return this.$store.state.setting.settings ? this.$store.state.setting.settings.config.option.bettingOption.sportsFolder.filter(e => e.active === true) : []
    },
    // 마감시간
    endTime: function(){
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '가상축구').endTime
    },
    ...mapState({
      originList: state => state.live.originList,
      user: state => state.user.user,
      cartItem: state => state.live.cart,
    }),
  },
  mounted: function() {
    window.addEventListener('scroll', this.handleScroll)
    this.initFolder()
    setInterval(this.removeEvents, 1000)
    this.$store.dispatch('ALL_CLEAR_LIVE_ITEM')
  },
  beforeDestroy: function() {
    window.removeEventListener('scroll', this.handleScroll)
    this.$store.dispatch('ALL_CLEAR_LIVE_ITEM')
  },
  methods: {
    handleScroll: function () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition > 200) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }
    },
    toggleStick: function(){
      this.sticky = !this.sticky;
    },
    initFolder: function(){
      return this.$store.dispatch('GET_LIVE_LIST', { payload: this.payload })
          .then(() => {
            this.isLoading = true;
            this.lists = this.originList.filter(item => {
              // oddsTeam1, oddsTeam2가 null이거나 문자열일 수 있으므로 숫자로 변환
              const odd1 = parseFloat(item.oddsTeam1) || 0;
              const odd2 = parseFloat(item.oddsTeam2) || 0;
              
              // 둘 중 하나라도 3을 초과하면 제외, 즉 3 이하인 것만 필터링
              return odd1 <= 3 && odd2 <= 3;
            });
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    // 페이지 이동
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    // 게임종목
    changeCategory: function(category){
      this.category = category;
      if (!category) return this.lists = this.originList;
      this.lists = this.originList.filter(e=> e.sportsName === category);
    },
    // 베팅카트에 담기
    addCart: function (game, side) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.한국형.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
      } else return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.isNotLogin);

      const item = {
        gameId: game.seq,
        gameSeq: game.seq,
        betsSeq: null,
        betsId: null,
        betSide: side,
        betsStatus: null,
        betsName: null,
        betsUpdated: this.autoUpdating,
        betsPrevOdds: side === 'home' ?  game.oddsTeam1 : game.oddsTeam2,
        sportsId: null,
        marketId: null,
        foldersSeq: null,
        foldersType: null,
        odds: side === 'home' ?  game.oddsTeam1 : game.oddsTeam2,
        line: game.oddsTeam3,
        leagueNameKr: game.leagueName,
        leagueNameEn: game.leagueName,
        leagueNameIcon: null,
        sportNameKr: game.sportName,
        sportNameEn: game.sportName,
        sportNameIcon: game.null,
        homeNameKr: game.teamName1,
        homeNameEn: game.teamName1,
        awayNameKr: game.teamName2 ,
        awayNameEn: game.teamName2,
        marketNameKr: game?.betType === 'underover' ? '오버언더' : game?.betType === 'handicap' ? '핸디캡' : game?.betType === '12' ? '승패' : game?.betType,
        marketNameEn: game?.betType === 'underover' ? '오버언더' : game?.betType === 'handicap' ? '핸디캡' : game?.betType === '12' ? '승패' : game?.betType,
        startTime: game.gameDate,
        odds1: game.oddsTeam1,
        odds2: game.oddsTeam2,
        odds3: game.oddsTeam3,
        betType: '라이브',
        detailType: null,
        currentPeriod: 1,
        currentTime: 1,
        homeScore: 0,
        awayScore: 0,
        penalty: null
      };
      this.$store.dispatch('ADD_CART_LIVE_ITEM', item);
    }, // 카트내역 체크해서 표시
    checkCart: function (game, side) {
      let index = this.cartItem.find(item => item.gameSeq === game.seq && item.betSide === side)
      return !!index;
    },
    // 시간이 지난 항목들 안보이게 설정
    removeEvents: function () {
      if (this.lists && this.lists.length > 0) {
        let endEvents = this.lists.filter(e => this.gameStarted(e.gameDate))
        endEvents.forEach((ele) => {
          let index = this.lists.indexOf(ele)
          if (index >= 0) this.lists.splice(index, 1)
        })
      }
    },
    gameStarted: function (date) {
      let now = moment().add(9, 'hour');
      let startDate = moment(date);
      const remainSeconds = startDate.diff(now, 'seconds');
      return this.endTime >= remainSeconds;
    },
    toggleAutoUpdating: function(){
      this.autoUpdating = !this.autoUpdating;
    },
  },
}
</script>

<style scoped>
.event-detail-list-move {
  transition: transform 0.8s;
}
.event-detail-list {
  transition: transform 0.8s;
}
.fixedCart {
  top: 80px;
}
</style>
