<template>
  <div class="m-game-wrap">
    <div class="m-game-body"
         v-for="(row, index) in lists"
         v-if="row.status === '진행'"
         :key="'mobileGameList' + index"
    >
      <template v-if="row.isVisible ==='y'">
        <div class="m-game">
          <div class="day">
            <img v-if="row.sport.icon" :src="row.sport.icon">
            <img style="margin-left: 4px;" v-if="row.location.icon" :src="row.location.icon" alt="">
            <span class="t2">
              {{ row.league | leagueName }}
            </span>
            <span class="t3">{{ row | currentPeriod3('period') }}</span>
            <div class="right" v-if="row.foldersCnt.cnt > 0" @click="moveMobileGameDetail(row)">
              <span style="color:#ffa800;">[{{ row.league.maxAmount | amtKor }}]</span>
              + {{ row.foldersCnt.cnt }}
            </div>
            <div class="right" v-else>
              <span style="color:#ffa800; margin-right: 10px;">[{{ row.league.maxAmount | amtKor }}]</span>
              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
            </div>
          </div>
          <div class="team" @click="moveMobileGameDetail(row)" style="cursor: pointer;">
            <div class="name">
              <span>
                {{ row.homeTeam | teamName }}
              </span>
              <!--     스코어         -->
              <span>
                <strong class="orange">
                  {{ row.homeResult }}
                </strong>
                vs
                <strong class="orange">
                  {{ row.awayResult }}
                </strong>
              </span>
              <span>
                {{ row.awayTeam | teamName }}
              </span>
            </div>
          </div>
          <template v-if="checkBlockGame(row)">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
          <template v-else-if="row.folders.length === 0 && row.foldersCnt.cnt === 0">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
          <template v-else-if="row.folders.length === 0 && row.foldersCnt.cnt > 0">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
            </div>
          </template>
          <template v-else>
            <div class="choice" v-if="row.folders[0].isVisible === 'y' && row.folders[0].isSuspended === 'n' && !row.folders[0].bets.find(e => Number(e.price) === 0)">
              <MPrice
                  :bets="row.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === 'Home')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
              <MPrice
                  v-if="row.folders[0].bets.find(e => e.name === 'X')"
                  :bets="row.folders[0].bets.find(e => e.name === 'X')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
              <MPrice
                  :bets="row.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' ||  e.name === 'Away')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
            </div>
            <div class="choice" v-else-if="row.folders[0].isVisible === 'y' && (row.folders[0].isSuspended === 'y' || row.folders[0].bets.find(e => Number(e.price) === 0))">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl v-if="row.folders[0].bets.find(e => e.name ==='X')">
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
            <div class="choice" v-else-if="row.folders[0].isVisible === 'n'">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>

        </div>
      </template>
      <RenewalMobileDetail
      v-if="checkArray(row.id)"
      :game="row"
      ></RenewalMobileDetail>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import MPrice from '@/components/Game/Sports/Inplay/MInplayListPrice'
import RenewalMobileDetail from '@/components/Game/Sports/Inplay/RenewalInplayMobileDetail'

export default {
  name: "MInpList",
  components: {
    // Category,
    MPrice,
    RenewalMobileDetail,
  },
  props: ['originList', 'addCart', 'checkCart', 'waitList', 'isLoading', 'checkBlockGame'],
  computed: {
    lists: function () {
      return this.originList
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
    }),
  },
  data: function () {
    return {
      openDetailGame: [],
    }
  },
  mounted: function () {
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  methods: {
    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        const game = this.lists.find(e => String(e.id) === String(result.EventId))
        if (game) {
          const score = result.Score;
          if (score) {
            game.homeResult = score.split(':')[0] || 0;
            game.awayResult = score.split(':')[1] || 0;
            game.currentPeriod = result.Period;
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('matchUpdate', matchData);
        const game = this.originList.find(e => String(e.id) === String(matchData.Id));
        if (game) {
          const cartItem = this.cartItem.find(e => String(e.gameId) === String(matchData.Id));
          if (cartItem) {
            cartItem.betsStatus = matchData.IsSuspended || !matchData.IsVisible ? 'y' : 'n';
            cartItem.betsUpdated = true;
          }
          if (matchData.IsStarted) game.status = '진행';
          game.isVisible = matchData.IsVisible ? 'y' : 'n';
          game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2) {
            game.status = '종료';
            setTimeout(() => {
              this.removeGames(game.seq)
            }, 3000)
          }
        }
      }
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index>=0) {
          this.lists.splice(index, 1)
        }
      }
      // 카트 아이템수정
      if (this.cartItem){
        let index = this.cartItem.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.$store.state.inplay.cart.splice(index, 1);
        }
      }
    },

    // 배당 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      this.$store.dispatch('UPDATE_INPLAY_LIST', result);
    },
    moveMobileGameDetail: function(game){
      //this.selectedGame(game)
      this.$router.push({path: `/sport/live/d?gid=${game.id}`})
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailGame = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    selectedGame(game){
      console.log(game)
      this.toggle(this.openDetailGame, game.id)
      console.log(this.openDetailGame)
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },
  },
}
</script>

<style scoped>

</style>
